<template>
  <CardsRow>
    <Row class="row">
      <slot name="top-left" />
      <slot name="top-right" />
    </Row>
    <Row>
      <slot name="bottom-left" />
      <slot name="bottom-right" />
    </Row>
  </CardsRow>
</template>
<script>
import CardsRow from '@/components/cards/cards-row.vue'
import Row from '@/components/cards/row.vue'
export default {
  components: {
    CardsRow,
    Row
  }
}
</script>
<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-evenly;
}
</style>
