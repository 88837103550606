<template>
  <div class="card" :style="{ marginTop: gap.marginTop, marginBottom: gap.marginBottom, marginLeft: gap.marginLeft, marginRight: gap.marginRight, paddingBottom: gap.paddingBottom, paddingTop: gap.paddingTop, paddingLeft: gap.paddingLeft, paddingRight: gap.paddingRight }">
    <div class="card__logo" :style="{ width: img.width, height: img.height, marginRight: gap.logoMarginRight }" >
      <img :src="img.src" alt="" />
    </div>
    <div class="card__content" :style="{ width: gap.ContentWidth }">
      <div class="card__title" :style="{ fontSize: title.fontSize, color: title.color, lineHeight: title.lineHeight}" >
        {{ title.text }}
      </div>
      <div class="card__desc" :style="{ fontSize: content.fontSize, color: content.color, lineHeight: content.lineHeight }" >
        {{ content.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    gap: { type: Object, default: () => ({}) },
    img: { type: Object, default: () => ({}) },
    title: { type: Object, default: () => ({}) },
    content: { type: Object, default: () => ({}) },
  },
})
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(70, 83, 110, 0.29);
  border-radius: 5px;
}
.card__title {
  margin-top: -5px;
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 47px;
}
.card__desc {
  display: flex;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
}
</style>
